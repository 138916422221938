export class Cliente {

    public intId?:number;
    public strNombre?:string;
    public strApellidos?:string;
    public strDni?:string;
    public strEmail?:string;
    public strDireccion?:string;
    public strCP?:string;
    public strPoblacion?:string;
    public strProvincia?:string;
    public strPais?:string;
    public strTelefono1?:string;
    public strTelefono2?:string;
    public strActivo?:string;
    public strUserId?:string;
    public strTipoCliente?:string;
    //Unicamente se usa para la insercion de clientes (NUNCA se recupera)
    public strPassword?:string;
    public strPasswordConfirm?:string;
    public strUserName?:string;

    //lo uso para filtros
    constructor(nombre?:string,apellidos?:string,dni?:string,email?:string,pass?:string){
        this.strNombre=nombre;
        this.strApellidos=apellidos;
        this.strDni=dni;
        this.strEmail=email;
        this.strPassword=pass;
    }

}
