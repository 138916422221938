import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }

  // private productoId=environment.productoId;
  public url: string = environment.baseUrl;
  headers = new HttpHeaders().set('Content-Type','application/json');

  apiUsuario(cookie: string): Observable<any>{
    let dato = '{"strId":"'+ cookie +'"}';     
    return this.http.post<any>(this.url + '/api/usuario', dato, {headers: this.headers});
  }

  token(mistrUsuario: string, mistrPassword: string): Observable<any>{
    var data = "username=" + mistrUsuario + "&password=" + mistrPassword + "&grant_type=password";
    return this.http.post<any>(this.url + '/token' , data, { headers: this.headers });
  } 

  apiSesion(strUsuario: string, strPassword: string, intRolTipo: number, miCookie: string): Observable<any>{
    let dato = '{"strUsuario": "'+ strUsuario +'", "strContrasena":"'+ strPassword + '", "intRolTipo":' + intRolTipo +', "strCookie":"'+ miCookie +'"}';
    return this.http.post<any>(this.url + '/api/sesion/autenticacion', dato, {headers: this.headers});
  }

  /********** Obtener LOGO *************/

  getLogo(cookie):Observable<any>{
    let urlCompleta=this.url+'/api/producto?strCookie='+cookie;
 
    return this.http.get<any>(urlCompleta,{headers:this.headers});
  }

  getDatosGeneralesEst(cookie):Observable<any>{
    let urlCompleta=this.url+'/api/establecimiento/general?strCookie='+cookie;
 
    return this.http.get<any>(urlCompleta,{headers:this.headers});
  }
}
