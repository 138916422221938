
import {Injectable} from "@angular/core";
import { Router, NavigationExtras } from '@angular/router';
import { Sesion } from '../models/Sesion';
import { User } from '../models/User';
import { SesionPagos } from '../models/sesion-pagos';

@Injectable()
export class StorageService {

  private localStorageService;
  private currentSession : Sesion = null;
  // private currentSessionP : SesionPagos = null;

  constructor(private router: Router) {
    this.localStorageService = localStorage;
    this.currentSession = this.loadSessionData();
    // this.currentSessionP = this.loadSessionDataP();
  }

  setCurrentSession(session: Sesion): void {
    this.currentSession = session;
    this.localStorageService.setItem('cookieExtranet', JSON.stringify(session));
    //this.localStorageService.setItem('cookieUser', JSON.stringify(session.strCookie));
  }

  // setSessionPagos(sessionP: SesionPagos): void {
  //   this.currentSessionP = sessionP;
  //   this.localStorageService.setItem('cookieExtranetPagos', JSON.stringify(sessionP));
  // }

  loadSessionData(): Sesion{
    var sessionStr = this.localStorageService.getItem('cookieExtranet');
    //var sessionStr2 = this.localStorageService.getItem('cookieUser');
    return (sessionStr) ? <Sesion> JSON.parse(sessionStr) : null;
    //(sessionStr2) ? <any> JSON.parse(sessionStr2) : null;
  }

  // loadSessionDataP(): SesionPagos{
  //   var sessionStr = this.localStorageService.getItem('cookieExtranetPagos');
  //   return (sessionStr) ? <SesionPagos> JSON.parse(sessionStr) : null;
  // }

  getCurrentSession(): Sesion {
    return this.currentSession;
  }

  // getCurrentSessionP(): SesionPagos {
  //   return this.currentSessionP;
  // }

  removeCurrentSession(): void {
    this.localStorageService.removeItem('cookieExtranet');
    //this.localStorageService.removeItem('cookieUser');
    this.currentSession = null;
  }

  // removeCurrentSessionP(): void {
  //   this.localStorageService.removeItem('cookieExtranetPagos');
  //   //this.localStorageService.removeItem('cookieUser');
  //   this.currentSession = null;
  // }

  getCurrentUser(): User {
    var session: Sesion = this.getCurrentSession();
    return (session && session.user) ? session.user : null;
  };

  isAuthenticated(): boolean {
    return (this.getCurrentToken() != null) ? true : false;
  };

  getCurrentToken(): string {
    var session = this.getCurrentSession();
    return (session && session.miToken.access_token) ? session.miToken.access_token : null;
  };

  // getCurrentTokenOra(): string {
  //   var session = this.getCurrentSession();
  //   return (session && session.strTokenOra) ? session.strTokenOra : null;
  // };

  logout(): void{
    
    //this.router.navigate(['/login']);
    //console.log(this.getCurrentSession().strCookie);

    // let extra: NavigationExtras = {
    //   queryParams: {
    //     'coo':this.getCurrentSession().strCookie,

    //   }
    // }
    // this.router.navigate(["/login"]);    

    window.location.href='http://infiteccontrol.infitec.es/servicios/solicita?est='+ this.getCurrentSession().strEstablecimientoId +'&pro='+  this.getCurrentSession().strProductoId+'';

    this.removeCurrentSession();
  }

}
