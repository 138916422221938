import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { User } from 'src/app/models/User';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { VentanaEmergenteComponent } from '../ventana-emergente/ventana-emergente.component';
import { environment } from 'src/environments/environment';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})

export class MenuComponent implements OnInit {

  loginUserData: User;
  abierto:boolean=true;
  cerrado:boolean=false;
  showSpinner: boolean = false;

  //personalizacion de menu
  isAdmin=false;
  isCliente=false
  private rolAdmin: string = environment.rolAdmin;
  private rolCliente: string = environment.rolCliente;
  hide = true;
  menuHide=false;

  //LOGO
  textoApp:string;
  imgLogo:string;

  constructor(private storageService: StorageService, private dialog: MatDialog, private miservicio:RestService) { }

  ngOnInit() {    
    this.loginUserData=this.storageService.getCurrentUser();
    // console.log(this.loginUserData)
    this.tokenExpires();

    

    // console.log(this.storageService.getCurrentSession())
    this.textoApp=this.storageService.getCurrentSession().strNombreApp;
    this.imgLogo=this.storageService.getCurrentSession().strLogo;

    switch(this.storageService.getCurrentUser().strRolId){
      case this.rolAdmin:   
        this.isCliente=false;     
        return this.isAdmin=true;

       case this.rolCliente:
          this.isCliente=true;
         return this.isAdmin=false; 
    }
  }

  openCloseMenu(){
    document.getElementById("menuAccount").style.display="none";
  }

  openNav() {
    document.getElementById("miSidenav").style.width="250px";
    this.abierto=true;
    this.cerrado=false;
  }

   closeNav() {
    document.getElementById("miSidenav").style.width="0";  
    this.abierto=false;
    this.cerrado=true;    
  }

  public logout(): void{
    this.showSpinner = true;
    this.storageService.logout();

    // setTimeout(() => {
    //   this.showSpinner = false;
    //   this.storageService.logout();
    // }, 1000);  
  }

  tokenExpires(){
    var event = new Date(this.storageService.getCurrentSession().miToken[".expires"]);
    var jsonDate = event.toJSON();
    var today = new FormControl(new Date()).value.toJSON();
    if(jsonDate>today){
    }else if(jsonDate<today){
      setTimeout(() => {
        this.openWindow();
      }, 100); 
    }  
  }

  openWindow(){
    let dialogRef = this.dialog.open(VentanaEmergenteComponent, {
      width: '600px',
      data:{
        texto:"Tu sesión ha caducado...",
        boton:false
      }
    });
    dialogRef.afterClosed().subscribe(result => {

      // console.log(result)
      if(result==="delete"){
        this.storageService.logout();
      }

    });
  }

}
