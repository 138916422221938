import { Component, OnInit, Inject } from '@angular/core';
import { Cliente } from 'src/app/models/cliente';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { RestService } from 'src/app/services/rest.service';
import { Funciones } from 'src/app/resources/Funciones';
import { ChangePassword } from 'src/app/models/change-password';

@Component({
  selector: 'app-detalle-cliente',
  templateUrl: './detalle-cliente.component.html',
  styleUrls: ['./detalle-cliente.component.scss']
})
export class DetalleClienteComponent implements OnInit {

  miCliente:Cliente=new Cliente();
  existError=false;
  mensajeError:string;
  formNewCliente: FormGroup;
  submitted = false;
  isChecked:boolean;
  Estado:string;
  dniError=false;
  miFuncion: Funciones = new Funciones();

  //
  strEmail:string="";
  strPassword:string="";
  strPasswordConfirm:string="";

  tituloFormulario:string;
  showSpinner=false;

  miChangePassword:ChangePassword=new ChangePassword();

  constructor(private _formBuilder: FormBuilder,private thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) private id: any,
              private miServicio:RestService) { }

  ngOnInit() {
    // console.log(this.miCliente)

    this.formNewCliente = this._formBuilder.group({
      nombre: ['', Validators.required],
      apellido: [''
      // , Validators.required
    ],
      dni: [''
      // , Validators.required
    ],
      usuario: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])),
      pass:new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        //Contraseña: minimo 1 mayuscula, 1 minuscula, 1 numero y 1 caracter especial
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d$@$!%*?&].{7,}'),
      ])),
      confirmPass:new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        //Contraseña: minimo 1 mayuscula, 1 minuscula, 1 numero y 1 caracter especial
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d$@$!%*?&].{7,}'),
      ])),
      direccion: [''],
      poblacion: [''],
      provincia: [''],
      cp: [''],
      pais: [''],
      tel1: [''],
      tel2: [''],
      activo: ['']
    })

    if(this.id!=null){
      //Modo edicion
      this.listarCliente();
      this.tituloFormulario="";
      this.formNewCliente.controls.usuario.disable();
    }else{
      this.isChecked=true;
      this.Estado="Activo";
      this.tituloFormulario="Nuevo cliente";
    }
  }

  pulsa(){
    if(this.isChecked){
      this.Estado='Inactivo';      
    }else{
      this.Estado="Activo";      
    }
  }

  listarCliente(){
    this.miServicio.getPass(this.id).subscribe(data=>{
      console.log(data)
      if(data.miRespuesta.booOk){
        //asigno el titulo
        this.tituloFormulario+=data.miListCliente[0].strNombre + " " + data.miListCliente[0].strApellidos;

        this.miCliente=data.miListCliente[0];
        this.strEmail=data.miListCliente[0].strEmail;

        this.miCliente.strPassword=data.miRespuesta.strValor;
        this.miCliente.strPasswordConfirm=data.miRespuesta.strValor;

        if(this.miCliente.strActivo==="S"){
          this.isChecked=true;
          this.Estado="Activo";
        }else{
          this.isChecked=false;
          this.Estado="Inactivo";
        }
      }
    })
  } 

  btnGuardar(){
    this.showSpinner=true;
    if(this.isChecked){
      this.miCliente.strActivo="S";
    }else{
      this.miCliente.strActivo="N";
    }
    this.miCliente.strTipoCliente="2";

    this.submitted=true;
    //Validaciones del formulario
    if(this.formNewCliente.valid){    
      
      //POR EL MOMENTO SE DESHABILITA VALIDACION DE DNI
      
      // //verifico que el dni sea valido
      // // if(this.miFuncion.nif(this.formNewCliente.controls.dni.value)){
      //   //oculto el error de dni erroneo
      //   this.dniError=false;   
      
      if(this.miCliente.strPassword===this.miCliente.strPasswordConfirm){
        this.existError=false;

        if(this.id!=null){
          //modo update
          this.update();
        }else{
          //modo insert
          this.insert();
        }

      }else{
        this.existError=true;
        this.mensajeError="La contraseña no coincide."
        this.showSpinner=false;
      }        

      // }else{
      //   this.dniError=true;
      // }            
    }else{
      this.showSpinner=false;
    }    
  }

  insert(){
    this.miServicio.postCliente(this.miCliente).subscribe(datos=>{
      // console.log(datos)
      this.showSpinner=false;
      if(datos.miRespuesta.booOk){
        this.thisDialogRef.close(datos.miListCliente);
      }else{
        this.existError=true;
        this.mensajeError=datos.miRespuesta.strMensaje;
      }
    })
  }

  update(){
    this.miServicio.putCliente(this.miCliente).subscribe(datos=>{
      // console.log(datos)
      this.showSpinner=false;
      if(datos.miRespuesta.booOk){
        this.thisDialogRef.close(datos.miListCliente);
      }else{
        this.existError=true;
        this.mensajeError=datos.miRespuesta.strMensaje;
      }
    })
  }

  cancelar(){
    this.thisDialogRef.close();
  }

}
