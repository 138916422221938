import { Component, OnInit } from '@angular/core';
import { Cliente } from 'src/app/models/cliente';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Funciones } from 'src/app/resources/Funciones';
import { RestService } from 'src/app/services/rest.service';
import { MatDialog } from '@angular/material';
import { StorageService } from 'src/app/services/storage.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-mis-datos',
  templateUrl: './mis-datos.component.html',
  styleUrls: ['./mis-datos.component.scss']
})
export class MisDatosComponent implements OnInit {

  miCliente:Cliente=new Cliente();
  existError=false;
  mensajeError:string;
  formNewCliente: FormGroup;
  submitted = false;
  isChecked:boolean;
  // Estado:string;
  dniError=false;
  miFuncion: Funciones = new Funciones();

  //
  strEmail:string="";
  strPassword:string="";
  strPasswordConfirm:string="";

  // tituloFormulario:string;
  showSpinner=false;

  constructor(private miservicio:RestService, private dialog: MatDialog, private storageService:StorageService,public datepipe: DatePipe,private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.formNewCliente = this._formBuilder.group({
      nombre: [''],
      apellido: [''],
      dni: [''],
      usuario: [],
      email: [],
      // pass:[],
      // confirmPass:[],
      direccion: [''],
      poblacion: [''],
      provincia: [''],
      cp: [''],
      pais: [''],
      tel1: [''],
      tel2: [''],
      // activo: ['']
    })
    this.listarCliente();
    this.formNewCliente.disable();
  }

  listarCliente(){
    this.miservicio.getCliente(this.storageService.getCurrentUser().strEntidadId).subscribe(datos=>{
      // console.log(datos)

      //asigno el titulo
      // this.tituloFormulario+=datos.miListCliente[0].strNombre + " " + datos.miListCliente[0].strApellidos;

      this.miCliente=datos.miListCliente[0];
      // this.strEmail=datos.miListCliente[0].strEmail;
      // this.strPassword=datos.miListCliente[0].strPassword;
      // this.strPasswordConfirm=datos.miListCliente[0].strPasswordConfirm;
      // if(this.miCliente.strActivo==="S"){
      //   this.isChecked=true;
      //   this.Estado="Activo";
      // }else{
      //   this.isChecked=false;
      //   this.Estado="Inactivo";
      // }
    })
  } 

}
