import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private rolId: string = environment.rolAdmin;

  constructor(private storageService: StorageService, private router: Router) { }

    /*canLoad() {
      let url: string = window.location.pathname;
      console.log('Url:'+ url);
      return true;
    }*/

    canActivate() {
      
      let loginUserData = this.storageService.getCurrentUser();
      
      if (this.storageService.isAuthenticated()) {
       return true;
      }

        this.router.navigate(['/login']);
       return false;
    }
  
}
