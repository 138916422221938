export class FacturaVenta{
    public intId?: number; 
    public datFechaRegistro?: Date; 
    public datFechaActualizacion?: Date; 
    public strOrigen?: string; 
    public strTipo?: string; 
    public intIdCliente?: number; 
    public strNombreCliente?: string; 
    public strApellidosCliente?: string; 
    public strConcepto?: string; 
    public decImporte?: number; 
    public strFormaPago?: string; 
    public strFormaPagoTexto?: string; 
    public strEstado?: string; 
    public strEstadoTexto?:string;
    public strDatosTransaccion?: string; 
    public strArchivoTransaccion?:string;
    public datFechaInicioTransaccion?: Date;
    public intNumeroTransaccion?:number;
    public booFacturaPDF?:boolean;
    public strNumeroFacturaExterno?:string;
    public strObservacion?:string;
    public datFechaFinTransaccion?:Date;
    public booMuestraFechaFinTransaccion?:boolean;
    // public datFechaRegistroObservacion?:Date;
    public strEstadoTransaccion?:string;
    public strNumCuentaCliente?:string;
    public strJustificantePago?:string;
    public booJustificantePago?:boolean;
    public strExtensionJustificante?:string;

    public strSerie?:string;
    public intEjercicio?:number;
    public intNFactura?:number;
    public datFecha?:Date;
    public strObservaciones?:string;
    
    //lo uso para filtros
    constructor(nombreCliente?:string, apellidosCliente?:string, concepto?:string, importe?:any, NFactura?:any){
        this.strNombreCliente=nombreCliente;
        this.strApellidosCliente=apellidosCliente;
        this.strConcepto=concepto;
        this.decImporte=importe;
        this.strNumeroFacturaExterno=NFactura;
    }
}