import { Component, OnInit,ViewChild, HostListener, ElementRef } from '@angular/core';
import {MatTableDataSource, MatSort, DateAdapter,MatPaginator, MatDialog, MatPaginatorIntl, MatDatepickerInputEvent} from '@angular/material';
import { RestService } from 'src/app/services/rest.service';
import { StorageService } from 'src/app/services/storage.service';
import { CustomPaginator, Funciones } from 'src/app/resources/Funciones';
import { DetalleOrdenCobroComponent } from '../detalle-orden-cobro/detalle-orden-cobro.component';
import { FacturaVenta } from 'src/app/models/FacturaVenta';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-facturas-pendientes',
  templateUrl: './facturas-pendientes.component.html',
  styleUrls: ['./facturas-pendientes.component.scss']
})
export class FacturasPendientesComponent implements OnInit {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  data:FacturaVenta[]=[];
  // displayedColumns = ['strNumeroFacturaExterno','strApellidosCliente','datFechaFinTransaccion','decImporte', 'strEstado', 'strTipo','booFacturaPDF','booJustificantePago'];
  displayedColumns = ['datFechaRegistro','strNumeroFacturaExterno','strApellidosCliente','decImporte', 'strFormaPago', 'datFechaFinTransaccion',
                      'strEstado', 'strTipo','booFacturaPDF','booJustificantePago'];
  dataSource;

  dataTodo:FacturaVenta[]=[];
  listFiltrada:FacturaVenta[]=[];

  miFiltro:FacturaVenta=new FacturaVenta('','','','','');
  filtro:any;

  private rolAdmin: string = environment.rolAdmin;
  private rolCliente: string = environment.rolCliente;
  isAdmin=false;

  teclaAlt:any;
  otraTecla:any;

  startDate = new Date(1990, 0, 10);
  filtroFecha:any;
  nombreClienteFiltro:any;

  dataExcel:datosFacturasVenta[]=[];

  constructor(private miservicio:RestService, private dialog: MatDialog, private storageService:StorageService,public datepipe: DatePipe) { }

  ngOnInit() {
    this.tipoUser();    
  }

  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) { 
    this.otraTecla=event.keyCode;  
    
    if(this.teclaAlt===78 && this.otraTecla===18){
      this.nuevo();
    }else if(this.teclaAlt===88 && this.otraTecla===18){
      this.exportAsXLSX();
    }else if(this.teclaAlt===66 && this.otraTecla===18){
      var myEl = document.getElementById('miFiltro');
      myEl.focus();
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    //alert(event.keyCode)
    this.teclaAlt=event.keyCode;    
  }

  
  tipoUser(){
    switch(this.storageService.getCurrentUser().strRolId){
      case this.rolAdmin:  
        this.listarOrdenes(0);
        this.isAdmin=true;      
        break;

       case this.rolCliente:
         this.listarOrdenes(this.storageService.getCurrentUser().strEntidadId);
         this.isAdmin=false;
         break; 
    }
  }

  downloadPDF(e){
    const linkSource = 'data:application/pdf;base64,' + e.strArchivoTransaccion;
    const downloadLink = document.createElement("a");
    const fileName = "Factura_"+ e.strNumeroFacturaExterno +".pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();        
}

  downloadJustificante(e){
    const linkSource = 'data:application/pdf;base64,' + e.strJustificantePago;
    const downloadLink = document.createElement("a");
    const fileName = "Justificante_Factura_"+ e.strNumeroFacturaExterno + "." + e.strExtensionJustificante;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();        
  }

  listarOrdenes(inCliente){
    this.miservicio.getFacturaVenta(0,inCliente,'P','').subscribe(datos=>{
      // console.log(datos)
      this.data=datos.miListFacturaVenta;  

      this.data.forEach(e => {
        // console.log(e.strEstado)
        switch(e.strEstado.toUpperCase()){
          case "C":
            e.strEstadoTexto="Creado";
            break;
          case "P":
            e.strEstadoTexto="Pendiente";
            break;
          case "F":
            e.strEstadoTexto="Finalizado";  
            break;
        }

        switch(e.strFormaPago){
          case "P":
            e.strFormaPagoTexto="Pasarela";
            break;
          case "C":
            e.strFormaPagoTexto="Cargo en cuenta";
            break;
          case "T":
            e.strFormaPagoTexto="Transferencia";  
            break;
        }
      });

      // this.listFiltrada=datos; 
      this.listFiltrada=datos.miListFacturaVenta; 
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;

      
      // console.log(this.data)
    })
  }

  nuevo(){
    let dialogRef = this.dialog.open(DetalleOrdenCobroComponent, {
      width:'600px'      
    });

    dialogRef.afterClosed().subscribe(result =>{
      if(result!=undefined){
        this.data.splice(0, this.data.length);
        this.data=result;  
        this.data.forEach(e => {
          switch(e.strEstado){
            case "C":
              e.strEstadoTexto="Creado";
              break;
            case "P":
              e.strEstadoTexto="Pendiente";
              break;
            case "F":
              e.strEstadoTexto="Finalizado";  
              break;
          }

          switch(e.strFormaPago){
            case "P":
              e.strFormaPagoTexto="Pasarela";
              break;
            case "C":
              e.strFormaPagoTexto="Cargo en cuenta";
              break;
            case "T":
              e.strFormaPagoTexto="Transferencia";  
              break;
          }
        });
        this.dataSource=new MatTableDataSource(result); 
        this.dataSource.sort = this.sort;
        this.dataSource.paginator=this.paginator;
      }      
    })
  }

  edita(e){
    let dialogRef = this.dialog.open(DetalleOrdenCobroComponent, {
      width:'600px',
      data:e.intId
    });

    dialogRef.afterClosed().subscribe(result =>{
      if(result!=undefined){
        this.data.splice(0, this.data.length);
        this.data=result;  
        this.data.forEach(e => {
          switch(e.strEstado){
            case "C":
              e.strEstadoTexto="Creado";
              break;
            case "P":
              e.strEstadoTexto="Pendiente";
              break;
            case "F":
              e.strEstadoTexto="Finalizada";  
              break;
          }

          switch(e.strFormaPago){
            case "P":
              e.strFormaPagoTexto="Pasarela";
              break;
            case "C":
              e.strFormaPagoTexto="Cargo en cuenta";
              break;
            case "T":
              e.strFormaPagoTexto="Transferencia";  
              break;
          }
        });
        this.dataSource=new MatTableDataSource(result);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator=this.paginator; 
      }      
    })
  }  

  buscar(){  
    // this.dataTodo=this.data;

    // //Elimino la casilla del nombre y lo asocio a los campos nombre y apellidos
    // this.miFiltro.strNombreCliente=this.miFiltro.strApellidosCliente;
    this.nombreClienteFiltro=this.miFiltro.strApellidosCliente;
    // console.log(this.nombreClienteFiltro)

    //   this.data=this.data.filter(i=>
    //                                 // i.strNombreCliente.toLowerCase().startsWith(this.miFiltro.strNombreCliente.toLowerCase()) && 
    //                                 i.strApellidosCliente.toLowerCase().includes(this.miFiltro.strApellidosCliente.toLowerCase()) && 
    //                                 i.strConcepto.toLowerCase().includes(this.miFiltro.strConcepto.toLowerCase()) && 
    //                                 // i.datFechaFinTransaccion.toString().includes(this.miFiltro.datFechaFinTransaccion.toString()) &&
    //                                 i.decImporte.toString().startsWith(this.miFiltro.decImporte.toString()) &&
    //                                 i.strNumeroFacturaExterno.toString().toLowerCase().includes(this.miFiltro.strNumeroFacturaExterno.toString().toLowerCase())
    //   );
    //   this.listFiltrada=this.data;
    //   this.data.forEach(e => {
    //     switch(e.strEstado){
    //       case "C":
    //         return e.strEstadoTexto="Creada";
    //       case "P":
    //         return e.strEstadoTexto="Pendiente";
    //       case "F":
    //         return e.strEstadoTexto="Finalizada";  
    //     }
    //   });
    //   this.dataSource=new MatTableDataSource(this.data);
    //   this.dataSource.sort = this.sort;
    //   this.dataSource.paginator=this.paginator;
    
    // this.data=this.dataTodo;

    this.busqueda()
  }

  modelChanged(e){
    // console.log(e)
    if(e!=""){
      // this.dataTodo=this.data;

      // this.data=this.data.filter(i=>this.datepipe.transform(i.datFechaFinTransaccion, 'yyyy-MM-dd')===e)     
      // this.listFiltrada=this.data;
      // this.data.forEach(i => {
      //   switch(i.strEstado){
      //     case "C":
      //       return i.strEstadoTexto="Creada";
      //     case "P":
      //       return i.strEstadoTexto="Pendiente";
      //     case "F":
      //       return i.strEstadoTexto="Finalizada";  
      //   }
      // });
      // this.dataSource=new MatTableDataSource(this.data);
      // this.dataSource.sort = this.sort;
      // this.dataSource.paginator=this.paginator;
    
      // this.data=this.dataTodo;
      this.filtroFecha=e;
    }else{
      this.filtroFecha=undefined   
    }

    this.busqueda()
  }

  busquedaGeneral(event: any){  
    // this.dataTodo=this.data;
    // this.filtro = event.target.value;
    // // console.log(event.target.value)

    //   this.data=this.data.filter(i=>
    //                                 i.strNombreCliente.toLowerCase().includes(this.filtro.toLowerCase()) ||
    //                                 i.strApellidosCliente.toLowerCase().includes(this.filtro.toLowerCase()) || 
    //                                 i.strConcepto.toLowerCase().includes(this.filtro.toLowerCase()) || 
    //                                 i.decImporte.toString().includes(this.filtro.toString()) ||
    //                                 i.strNumeroFacturaExterno.toString().toLowerCase().includes(this.filtro.toString().toLowerCase())
    //   );
    //   this.listFiltrada=this.data;
    //   this.data.forEach(e => {
    //     switch(e.strEstado){
    //       case "C":
    //         return e.strEstadoTexto="Creada";
    //       case "P":
    //         return e.strEstadoTexto="Pendiente";
    //       case "F":
    //         return e.strEstadoTexto="Finalizada";  
    //     }
    //   });
    //   this.dataSource=new MatTableDataSource(this.data);
    //   this.dataSource.sort = this.sort;
    //   this.dataSource.paginator=this.paginator;
    
    // this.data=this.dataTodo;

    this.busqueda()
  }

  busqueda() {
    this.dataTodo=this.data;
    // console.log(this.miFiltro)
    // console.log(this.nombreClienteFiltro)

    if(this.filtro!=null){
      this.data=this.data.filter(i=>
        i.strNombreCliente.toLowerCase().includes(this.filtro.toLowerCase()) ||
        i.strApellidosCliente.toLowerCase().includes(this.filtro.toLowerCase()) || 
        i.strConcepto.toLowerCase().includes(this.filtro.toLowerCase()) || 
        i.decImporte.toString().includes(this.filtro.toString()) ||
        i.strNumeroFacturaExterno.toString().toLowerCase().includes(this.filtro.toString().toLowerCase())
      );
    }

    if(this.miFiltro!=null){
      this.data=this.data.filter(i=>                                    
                                    (i.strApellidosCliente.toLowerCase().includes(this.miFiltro.strApellidosCliente.toLowerCase()) || i.strNombreCliente.toLowerCase().includes(this.nombreClienteFiltro.toLowerCase())) &&
                                    i.strConcepto.toLowerCase().includes(this.miFiltro.strConcepto.toLowerCase()) && 
                                    // i.datFechaFinTransaccion.toString().includes(this.miFiltro.datFechaFinTransaccion.toString()) &&
                                    i.decImporte.toString().startsWith(this.miFiltro.decImporte.toString()) &&
                                    i.strNumeroFacturaExterno.toString().toLowerCase().includes(this.miFiltro.strNumeroFacturaExterno.toString().toLowerCase())
                                    // || i.strNombreCliente.toLowerCase().includes(this.nombreClienteFiltro.toLowerCase())
      );
    }

    if(this.filtroFecha!=undefined){
      this.dataTodo=this.data;

      this.data=this.data.filter(i=>this.datepipe.transform(i.datFechaFinTransaccion, 'yyyy-MM-dd')===this.filtroFecha)           
    }

      this.listFiltrada=this.data;
      this.data.forEach(e => {
        switch(e.strEstado){
          case "C":
            e.strEstadoTexto="Creado";
            break;
          case "P":
            e.strEstadoTexto="Pendiente";
            break;
          case "F":
            e.strEstadoTexto="Finalizada";  
            break;
        }

        switch(e.strFormaPago){
          case "P":
            e.strFormaPagoTexto="Pasarela";
            break;
          case "C":
            e.strFormaPagoTexto="Cargo en cuenta";
            break;
          case "T":
            e.strFormaPagoTexto="Transferencia";  
            break;
        }
      });
      this.dataSource=new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
    
    this.data=this.dataTodo;        
  }

  exportAsXLSX():void {
    this.listFiltrada.forEach(e => {
      this.dataExcel.push({
        Fecha_Registro:this.datepipe.transform(e.datFechaRegistro, 'dd/MM/yyyy HH:mm'), 
        Fecha_Actualizacion:this.datepipe.transform(e.datFechaActualizacion, 'dd/MM/yyyy HH:mm'), 
        Nombre_Cliente:e.strNombreCliente, 
        Apellidos_Cliente:e.strApellidosCliente, 
        Num_Factura:e.strNumeroFacturaExterno, 
        Concepto:e.strConcepto, 
        Importe:e.decImporte, 
        Forma_Pago:e.strFormaPagoTexto,
        Estado_pago:e.strEstadoTexto,
        Fecha_Inicio_Transaccion:this.datepipe.transform(e.datFechaInicioTransaccion, 'dd/MM/yyyy HH:mm'), 
        Fecha_Fin_Transaccion:this.datepipe.transform(e.datFechaFinTransaccion, 'dd/MM/yyyy HH:mm'), 
        Num_Transaccion:e.intNumeroTransaccion,
        Estado_Transaccion:e.strEstadoTransaccion
      })
    });
    this.miservicio.exportAsExcelFile(this.dataExcel, 'Listado órdenes de cobro ');

    this.dataExcel.splice(0, this.dataExcel.length)
  }

}

export interface datosFacturasVenta {
  Fecha_Registro:any;
  Fecha_Actualizacion:any;
  Nombre_Cliente:any;
  Apellidos_Cliente:any;
  Num_Factura:any;
  Concepto:any;
  Importe:any;
  Forma_Pago:any;
  Estado_pago:any;
  Fecha_Inicio_Transaccion:any;
  Fecha_Fin_Transaccion:any;
  Num_Transaccion:any;
  Estado_Transaccion:any;  
}
