import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { RestService } from 'src/app/services/rest.service';
import { Cliente } from 'src/app/models/cliente';
import { DetalleClienteComponent } from '../detalle-cliente/detalle-cliente.component';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss']
})
export class ClientesComponent implements OnInit {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  data:Cliente[]=[];
  displayedColumns = ['strNombre','strApellidos','strDni','strEmail','strTipo'];
  dataSource;

  dataTodo:Cliente[]=[];
  listFiltrada:Cliente[]=[];

  miFiltro:Cliente=new Cliente('','','');
  // miFiltro:Cliente=new Cliente();
  filtro:any;

  teclaAlt:any;
  otraTecla:any;

  dataExcel:datosCliente[]=[];

  constructor(private miservicio:RestService, private dialog: MatDialog) { }

  ngOnInit() {
    this.listarClientes();
  }

  listarClientes(){
    this.miservicio.getCliente(0).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.data=datos.miListCliente;
        this.listFiltrada=datos.miListCliente; 
        this.dataSource=new MatTableDataSource(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator=this.paginator;
      }else{
        console.log('error')
      }
    })
  }

  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) { 
    this.otraTecla=event.keyCode;  
    
    if(this.teclaAlt===78 && this.otraTecla===18){
      this.nuevo();
    }else if(this.teclaAlt===88 && this.otraTecla===18){
      this.exportAsXLSX();
    }else if(this.teclaAlt===66 && this.otraTecla===18){
      var myEl = document.getElementById('miFiltroCaja');
      myEl.focus();
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    //alert(event.keyCode)
    this.teclaAlt=event.keyCode;    
  }

  nuevo(){
    let dialogRef = this.dialog.open(DetalleClienteComponent, {
      width:'600px'
    });

    dialogRef.afterClosed().subscribe(result =>{
      if(result!=undefined){
        // console.log(result)
        // this.data.splice(0, this.data.length);
        // this.data=result;  
        // this.dataSource=new MatTableDataSource(result); 
        // this.dataSource.sort = this.sort;
        // this.dataSource.paginator=this.paginator;

        let myObject:any;
        myObject=result[0];

        let miTicket=result[0].intId;

        var index = this.data.map(x => {
          return x.intId;
        }).indexOf(miTicket);

        if(result.length!=0){      
          this.data.splice(index, 1, myObject);
          this.dataSource=new MatTableDataSource(this.data);
        }
        this.dataSource=new MatTableDataSource(this.data);
        if(result.length===0){
          this.data.splice(index, 1);
        }
      }      
    })
  }

  edita(e){
    let dialogRef = this.dialog.open(DetalleClienteComponent, {
      width:'600px',
      data:e.intId
    });

    dialogRef.afterClosed().subscribe(result =>{
      // console.log(result)
      if(result!=undefined){
        // this.data.splice(0, this.data.length);
        // this.data=result;  
        // this.dataSource=new MatTableDataSource(result);
        // this.dataSource.sort = this.sort;
        // this.dataSource.paginator=this.paginator; 

          let myObject:any;
          myObject=result[0];
  
          let miTicket=result[0].intId;
  
          var index = this.data.map(x => {
            return x.intId;
          }).indexOf(miTicket);
  
          if(result.length!=0){      
            this.data.splice(index, 1, myObject);
            this.dataSource=new MatTableDataSource(this.data);
          }
          this.dataSource=new MatTableDataSource(this.data);
          if(result.length===0){
            this.data.splice(index, 1);
          }

      }      
    })
  }

  // buscar(){  
  //   this.dataTodo=this.data;

  //     this.data=this.data.filter(i=>i.strNombre.toLowerCase().startsWith(this.miFiltro.strNombre.toLowerCase()) && 
  //                                   i.strApellidos.toLowerCase().startsWith(this.miFiltro.strApellidos.toLowerCase()) && 
  //                                   i.strDni.toString().startsWith(this.miFiltro.strDni.toString())
  //     );
  //     this.listFiltrada=this.data;
  //     this.dataSource=new MatTableDataSource(this.data);
  //     this.dataSource.sort = this.sort;
  //     this.dataSource.paginator=this.paginator;      
    
  //   this.data=this.dataTodo;
  // }

  buscar(){  
    // this.nombreClienteFiltro=this.miFiltro.strApellidosCliente;    
    this.busqueda()
  }

  busquedaGeneral(event: any){
    this.busqueda()
  }

  busqueda() {
    
    
    this.dataTodo=this.data;

    if(this.filtro!=""){
      // console.log(this.filtro)
      this.data=this.data.filter(i=>
                                    i.strNombre.toLowerCase().includes(this.filtro.toLowerCase()) ||
                                    i.strApellidos.toLowerCase().includes(this.filtro.toLowerCase()) || 
                                    i.strDni.toLowerCase().includes(this.filtro.toLowerCase()) || 
                                    i.strTelefono1.toString().includes(this.filtro.toString()) ||
                                    i.strTelefono2.toString().toLowerCase().includes(this.filtro.toString().toLowerCase()) ||
                                    i.strEmail.toString().toLowerCase().includes(this.filtro.toString().toLowerCase())
      );
    }

    if(this.miFiltro!=null){
      // console.log(this.miFiltro)
      this.data=this.data.filter(i=>                                    
                                    i.strNombre.toLowerCase().includes(this.miFiltro.strNombre.toLowerCase()) && 
                                    i.strApellidos.toLowerCase().includes(this.miFiltro.strApellidos.toLowerCase()) && 
                                    i.strDni.toString().includes(this.miFiltro.strDni.toString())
      );
    }

    this.dataSource=new MatTableDataSource(this.data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator=this.paginator;
    
    this.data=this.dataTodo;        
  }

  exportAsXLSX():void {
    this.listFiltrada.forEach(e => {
      this.dataExcel.push({
        Nombre:e.strNombre, 
        Apellidos:e.strApellidos,  
        Dni:e.strDni, 
        Email:e.strEmail, 
        Direccion:e.strDireccion, 
        CP:e.strCP, 
        Poblacion:e.strPoblacion, 
        Provincia:e.strProvincia,
        Pais:e.strPais,
        Telefono1:e.strTelefono1,  
        Telefono2:e.strTelefono2
      })
    });
    this.miservicio.exportAsExcelFile(this.dataExcel, 'Listado clientes ');

    this.dataExcel.splice(0, this.dataExcel.length)
  }

}

export interface datosCliente {
  Nombre:any;
  Apellidos:any;
  Dni:any;
  Email:any;
  Direccion:any;
  CP:any;
  Poblacion:any;
  Provincia:any;
  Pais:any;
  Telefono1:any;
  Telefono2:any;  
}
