import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDatepickerInputEvent, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { FacturaVenta } from 'src/app/models/FacturaVenta';
import { RestService } from 'src/app/services/rest.service';
import { Cliente } from 'src/app/models/cliente';
import { Router } from '@angular/router';
import { FacturaVentaObservacion } from 'src/app/models/FacturaVentaObservacion';
import { DatePipe } from '@angular/common';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/resources/format-datepicker';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import { VentanaEmergenteComponent } from '../ventana-emergente/ventana-emergente.component';

@Component({
  selector: 'app-detalle-orden-cobro',
  templateUrl: './detalle-orden-cobro.component.html',
  styleUrls: ['./detalle-orden-cobro.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'}
  ]
})
export class DetalleOrdenCobroComponent implements OnInit {

  miFacturaVenta:FacturaVenta=new FacturaVenta();
  existError=false;
  mensajeError:string;
  formNewFacturaVenta: FormGroup;
  selectTipo:any;
  submitted: Boolean = false;
  miListClientes: Cliente[] = [];

  selectedCliente: any;
  enviaEmail=false;
  guardar=true;

  miCliente:Cliente=new Cliente();
  miListOrdenes: FacturaVenta[] = [];

  strEmail:string;
  nuevoEmail=false;

  miListFacturaVentaObservacion: FacturaVentaObservacion[] = [];
  @ViewChild("observacion", {static: false}) observacionField:ElementRef;
  isNotPDF=false;

  selectEstado:any;

  private imageSrc: string = '';
  private imageSrc2: string = '';
  imageUrl2:any;
  fFinTrans:any;
  startDate = new Date(1990, 0, 10);

  private rolAdmin: string = environment.rolAdmin;
  private rolCliente: string = environment.rolCliente;
  isAdmin=false;

  tituloFormulario:string;
  showSpinner=false;

  miFechaNueva:any;

  editaFacturaVenta:boolean;
  muestraCuentaCliente=false;

  encuentaPunto:boolean;

  codigoRedsys:string;

  constructor(private _formBuilder: FormBuilder,private thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) private id: any,public datepipe: DatePipe,
              private miServicio:RestService, private router:Router,private storageService: StorageService,private dateAdapter: DateAdapter<Date>,private dialog: MatDialog) {
                this.dateAdapter.setLocale('es-ES'); 
               }

  ngOnInit() {
    // console.log(new Date().toLocaleString())
    if(this.id!=null){
      //Modo edicion
      this.listarFactura();
      this.enviaEmail=true;
      this.listarObservaciones(this.id);
      this.tituloFormulario="Factura Nº ";
      this.editaFacturaVenta=true;
      document.getElementById("concepto").className="conceptoEdit"
    }else{
      //Modo insert
      // this.selectTipo="P";
      this.selectTipo="";
      this.selectEstado="C";
      this.tituloFormulario="Nueva factura";
      this.editaFacturaVenta=false;
      document.getElementById("concepto").className="conceptoInsert"
    }

    this.formNewFacturaVenta = this._formBuilder.group({
      // nombre: ['', Validators.required],
      cliente: ['', Validators.required],
      concepto: ['',Validators.required],
      importe: ['',Validators.required],
      // formapago: ['',Validators.required],
      numFactura: ['',Validators.required],
      estado:[''],
      fFinTransaccion:['']
      // email: new FormControl('', Validators.compose([
      //   // Validators.required,
      //   Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      // ]))
    })
  
    // this.formNewFacturaVenta.controls['codExt'].disable();
    this.listarClientes();
    this.tipoUser();

    
  }

  tipoUser(){
    switch(this.storageService.getCurrentUser().strRolId){
      case this.rolAdmin:  
        this.isAdmin=true;      
        break;

       case this.rolCliente:
         this.formNewFacturaVenta.controls.cliente.disable();
         this.formNewFacturaVenta.controls.concepto.disable();
         this.formNewFacturaVenta.controls.importe.disable();
        //  this.formNewFacturaVenta.controls.formapago.disable();
         this.formNewFacturaVenta.controls.numFactura.disable();
         this.formNewFacturaVenta.controls.estado.disable();
         this.formNewFacturaVenta.controls.fFinTransaccion.disable();
         this.enviaEmail=false;
         this.isAdmin=false;
         break; 
    }
  }

  addEvent( event: MatDatepickerInputEvent<Date>) {
    // let miFechaNueva= new Date(event.value);
    this.miFechaNueva=this.datepipe.transform(event.value, 'yyyy-MM-dd');
    // console.log(miFechaNueva)
    // this.miFacturaVenta.datFechaFinTransaccion=miFechaNueva
  }

  /***** Adjuntar Factura PDF *****/

  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /pdf-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.isNotPDF=true;
    }else{
      this.isNotPDF=false;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
    // console.log(file.name)
  }
  
  _handleReaderLoaded(e) {
    let reader = e.target;
    this.imageSrc = reader.result;  
  }

  addFacturaPDF(){
    this.imageSrc = this.imageSrc.substring(
      this.imageSrc.lastIndexOf(",")+1, 
      this.imageSrc.length
    );

    this.miFacturaVenta.strDatosTransaccion=this.imageSrc;
  }

  /***** Adjuntar justificante de pago *****/

  handleInputChange2(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    // var pattern = /pdf-*/;
    var reader = new FileReader();
    // if (!file.type.match(pattern)) {
    //   this.isNotPDF=true;
    // }else{
    //   this.isNotPDF=false;
    // }
    reader.onload = this._handleReaderLoaded2.bind(this);
    reader.readAsDataURL(file);
    // console.log(file.name)
    this.encuentaPunto=false;
    // console.log(this.imageUrl2)
    for(var i = file.name.length-1; i > 0; i--){
      // console.log(file.name[i] + " / " + i)
      if(this.encuentaPunto){

      }else{
        if(file.name[i]==="."){
          this.encuentaPunto=true;
          var x=i;
        }
      }
      
    }
    // console.log(x)
    this.miFacturaVenta.strExtensionJustificante=file.name.substring(file.name.length, x+1);
  }
  
  _handleReaderLoaded2(e) {
    let reader = e.target;
    this.imageSrc2 = reader.result;  
  }

  addJustificanteDF(){
    this.imageSrc2 = this.imageSrc2.substring(
      this.imageSrc2.lastIndexOf(",")+1, 
      this.imageSrc2.length
    );

    this.miFacturaVenta.strJustificantePago=this.imageSrc2;
  }


  listarObservaciones(intId){
    this.miServicio.getFacturaVentaObservaciones(this.storageService.getCurrentSession().strCookie,intId).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.miListFacturaVentaObservacion=datos.miListFacturaVentaObservacion;        
      }
    })
  }

  listarFactura(){
    this.miServicio.getFacturaVenta(this.id,0,'','').subscribe(datos=>{ 
      // console.log(datos)     
      if(datos.miRespuesta.booOk){      
        //asigno cliente
        this.selectedCliente=datos.miListFacturaVenta[0].intIdCliente;
        // this.fFinTrans = datos.miListFacturaVenta[0].datFechaFinTransaccion;
        //asigno forma pago
        // this.selectTipo=datos.miListFacturaVenta[0].strFormaPago;
        this.selectEstado=datos.miListFacturaVenta[0].strEstado;
        //asigno el resto de los datos
        this.miFacturaVenta=datos.miListFacturaVenta[0];
        //asigno el titulo
        this.tituloFormulario+=datos.miListFacturaVenta[0].strNumeroFacturaExterno + " para ";
        this.tituloFormulario += datos.miListFacturaVenta[0].strNombreCliente +" " + datos.miListFacturaVenta[0].strApellidosCliente;

        if(datos.miListFacturaVenta[0].booMuestraFechaFinTransaccion===false){
          this.miFacturaVenta.datFechaFinTransaccion=undefined;
        }

        if(datos.miListFacturaVenta[0].strNumCuentaCliente!=''){
          this.muestraCuentaCliente=true;
        }else{
          this.muestraCuentaCliente=false;
        }

        //Si el pago es por pasarela muestro el Nº redsys
        switch(datos.miListFacturaVenta[0].strFormaPago){
          case "P":
            if(this.isAdmin){
              this.codigoRedsys="(Pasarela - " + datos.miListFacturaVenta[0].intNumeroTransaccion + ")";
            }
            break;
        }
      }
    })
  }
  
  listarClientes(){
    this.miServicio.getCliente(0).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.miListClientes=datos.miListCliente;
      }
    })
  }

  btnGuardar(){
    this.showSpinner=true;
    // console.log(this.isNotPDF)
    if(this.isNotPDF===false){
      this.addFacturaPDF();
      this.addJustificanteDF();
      
      if(this.id!=null){
        //Modo edicion
        // this.formNewFacturaVenta.controls.formapago.setValue(this.selectTipo);
        this.formNewFacturaVenta.controls.estado.setValue(this.selectEstado);
        // console.log('guarda')
        this.guardaCambios();
      }else{
        //Modo insert
        // console.log('inserta')
        this.insertaNuevo();
      }
    }
  }

  insertaNuevo(){
    // console.log(this.selectedCliente)
    // this.miFacturaVenta.strFormaPago=this.selectTipo;
    this.miFacturaVenta.strEstado=this.selectEstado;
    this.miFacturaVenta.strOrigen="E";
    this.miFacturaVenta.strTipo="F";
    this.miFacturaVenta.intIdCliente=this.selectedCliente;   
    
    //Campos con datos fijos a mano
    this.miFacturaVenta.strSerie="A";
    this.miFacturaVenta.intEjercicio=new Date().getFullYear();
    // this.miFacturaVenta.datFecha=new Date(Date.parse(Date().toString()));
    this.miFacturaVenta.datFecha=new Date();
    // this.miFacturaVenta.strObservacion="";

    // console.log(this.miFacturaVenta)

    this.submitted = true;   
    
    if(this.formNewFacturaVenta.valid){
      this.miServicio.postFacturaVenta(this.miFacturaVenta,this.miFechaNueva).subscribe(datos=>{
        // console.log(datos)        
        // console.log(this.miFacturaVenta.strObservacion)
        this.showSpinner=false;
        if(datos.miRespuesta.booOk){
          //Asigno el numero de orden de cobro
          this.id=datos.miRespuesta.strValor;
          this.miFacturaVenta.intId=parseInt(datos.miRespuesta.strValor);
          this.enviaEmail=true;
          this.guardar=false;
          this.existError=false;
          this.miListOrdenes=datos.miListFacturaVenta;
          this.listarObservaciones(datos.miRespuesta.strValor);
          this.observacionField.nativeElement.value="";

          //Cambio el titulo
          this.tituloFormulario="";
          this.tituloFormulario="Factura Nº ";
          this.tituloFormulario+=datos.miListFacturaVenta[0].strNumeroFacturaExterno + " para ";
          this.tituloFormulario += datos.miListFacturaVenta[0].strNombreCliente +" " + datos.miListFacturaVenta[0].strApellidosCliente;
        }else{
          this.existError=true;
          this.enviaEmail=false;
          this.guardar=true;
          this.mensajeError=datos.miRespuesta.strMensaje;
          this.ventanaEmergente("Se ha producido un error, asegurese de que el fichero no está dañado.")
        }
      })
    }else{
      this.showSpinner=false;
    }
  }

  guardaCambios(){    
    this.miFacturaVenta.strEstado=this.selectEstado;
    this.miFacturaVenta.strOrigen="E";
    this.miFacturaVenta.strTipo="F";
    this.miFacturaVenta.intIdCliente=this.selectedCliente;    

    this.submitted = true;  

    switch(this.storageService.getCurrentUser().strRolId){
      case this.rolAdmin:  

    //CAROL 04/05/20
    //ELIMINO LA FUNCION SWITCH PORQUE SOLO PODRAN ACCEDER LOS CLIENTES A ESTA APLICACION
    //LOS ADMINISTRADORES ACCEDEN A LA APP PANEL CONTROL GENERAL

        if(this.formNewFacturaVenta.valid){
          this.miServicio.putFacturaVenta(this.miFacturaVenta,this.miFechaNueva).subscribe(datos=>{
            // console.log(this.miFacturaVenta.strObservacion)
            // console.log(datos)
            this.showSpinner=false;
            if(datos.miRespuesta.booOk){
              this.miFacturaVenta.strObservacion='';
              this.enviaEmail=true;
              this.guardar=false;
              this.existError=false;
              this.miListOrdenes=datos.miListFacturaVenta;
              this.listarObservaciones(this.id);
              this.observacionField.nativeElement.value="";
              // this.thisDialogRef.close(datos.miListFacturaVenta)
            }else{
              this.existError=true;
              this.enviaEmail=false;
              this.guardar=true;
              this.mensajeError=datos.miRespuesta.strMensaje;          
            }
          })
        }else{
          // console.log('error')
          this.showSpinner=false;
        }
        break;

       case this.rolCliente:

          this.miServicio.putFacturaVenta(this.miFacturaVenta,this.miFechaNueva).subscribe(datos=>{
            // console.log(this.miFacturaVenta.strObservacion)
            // console.log(datos)
            this.showSpinner=false;
            if(datos.miRespuesta.booOk){
              this.miFacturaVenta.strObservacion='';
              // this.enviaEmail=true;
              this.guardar=false;
              this.existError=false;
              this.miListOrdenes=datos.miListFacturaVenta;
              this.listarObservaciones(this.id);
              this.observacionField.nativeElement.value="";
              // this.thisDialogRef.close(datos.miListFacturaVenta)
            }else{
              this.existError=true;
              // this.enviaEmail=false;
              this.guardar=true;
              this.mensajeError=datos.miRespuesta.strMensaje;          
            }
          })
         break; 
    }
  }

  ventanaEmergente(texto){
    let dialogRef = this.dialog.open(VentanaEmergenteComponent, {
      width: '600px',
      data:{
        texto:texto,
        boton:false
      }
    });
  }

  //Proceso envio email, capturo la url
  email(){
    this.showSpinner=true;
    // console.log(this.id)
    this.miServicio.getCliente(this.selectedCliente).subscribe(datos=>{      
      this.miCliente=datos.miListCliente[0];
      //verifico si tiene e-mail
      if(this.miCliente.strEmail!=""){
        //funcion envia e-mail
        // this.miServicio.envioEmail(window.location.origin + "/pagos",this.selectedCliente,this.id,this.miFacturaVenta.strFormaPago).subscribe(datos=>{ºº
        this.miServicio.envioEmail(window.location.origin + "/pagos",this.selectedCliente,this.id).subscribe(datos=>{
          // console.log(datos)
          this.showSpinner=false;
          if(datos.booOk){
            if(this.miListOrdenes.length>0){
              this.thisDialogRef.close(this.miListOrdenes)
            }else{
              this.thisDialogRef.close();
            }
          }
        })
      }else{
        this.showSpinner=false;
        this.existError=true;
        this.mensajeError='El cliente no dispone de correo electrónico'
        // this.nuevoEmail=true;
      }
    })
  }

  cancelar(){
    if(this.miListOrdenes.length>0){
      this.thisDialogRef.close(this.miListOrdenes)
    }else{
      this.thisDialogRef.close();
    }    
  }

  

}
