import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { RestService } from 'src/app/services/rest.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Funciones } from 'src/app/resources/Funciones';
import { StorageService } from 'src/app/services/storage.service';
import { Login } from 'src/app/models/Login';
import { Respuesta } from 'src/app/models/Respuesta';
import { Sesion } from 'src/app/models/Sesion';
import { Token } from 'src/app/models/Token';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  LogInForm: FormGroup;
  strCoo: string;
  txtUsuario: string;
  txtContrasena: string;
  miRespuesta: Respuesta = new Respuesta();
  miFunciones: Funciones = new Funciones();
  miLogin: Login = new Login();
  miToken: Token = new Token();
  miSesion: Sesion = new Sesion();
  strUserNameRequest: string;
  strPasswordRequest: string;
  hide = true;
  showError=false;
  message:string;
  showSpinner = false;

  constructor(private miActivatedRoute: ActivatedRoute, private miRouter: Router, private formBuilder: FormBuilder, private miRestService: LoginService, //private miRestService: RestService,
              private miStorageService: StorageService) {
    this.miActivatedRoute.queryParams.subscribe(params => {
      this.strCoo=params["coo"];       
    });
   }

  ngOnInit() {
    this.LogInForm = this.formBuilder.group({
      username: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])),
      contrasena: new FormControl('', Validators.compose([
        Validators.required        
      ]))
    })

    // this.LogInForm = this.formBuilder.group({
    //   txtUsuario: ['', Validators.required],
    //   txtContrasena: ['', Validators.required]
    // });
  }

  LogIn(){
    try{
      this.showSpinner=true;
      // Solicito credenciales
      this.miRestService.apiUsuario(this.strCoo).subscribe((Resultado1: any) => {
        // Verifico que el dato que recibo sea un Login
        if (Resultado1.strUsuario != undefined) {
            this.miLogin = Resultado1;
            
            // Decripto la información
            this.strUserNameRequest = this.miFunciones.DesencriptaUsuarioPassword(this.miLogin.strUsuario);
            this.strPasswordRequest = this.miFunciones.DesencriptaUsuarioPassword(this.miLogin.strContrasena);

            // Solicito Token
            this.miRestService.token(this.strUserNameRequest, this.strPasswordRequest).subscribe((Resultado2: any) => {
              this.miToken = Resultado2;
              
              // Evaluo el token
              if (this.miToken.access_token != undefined && this.miToken.access_token != "") {
                // Valido credenciales del usuario
                this.miRestService.apiSesion(this.txtUsuario, this.txtContrasena, 1, this.miLogin.strCookie).subscribe((Resultado3: any) => {
                  // Verifico que el dato que recibo sea una Sesion
                  if (Resultado3.strCookie != undefined) {
                    this.miSesion = Resultado3;

                    // Adiciono datos faltantes
                    this.miSesion.strNombre = "";
                    this.miSesion.strNombreTitulo = "";
                    this.miSesion.miToken = this.miToken;     
                    
                    this.miRestService.getDatosGeneralesEst(this.miLogin.strCookie).subscribe(resultado4=>{
                      // console.log(resultado4)

                      if(resultado4.miRespuesta.booOk){
                        // Guardo datos en sesión
                        this.miSesion.strLogo=resultado4.miListEstablecimiento[0].strLogo;
                        this.miSesion.strEstablecimientoNombre=resultado4.miListEstablecimiento[0].strNombre;

                        this.miRestService.getLogo(this.miLogin.strCookie).subscribe(datos=>{
                          // console.log(datos);
                          if(datos.miRespuesta.booOk){
                            // Guardo datos en sesión
                            // this.miSesion.strLogo=datos.strValor;
                            this.miSesion.strNombreApp=datos.miListProducto[0].strNombre;
    
                            this.miStorageService.setCurrentSession(this.miSesion);
                            // Redirijo a pagina inicial del usuario
                            this.showSpinner = false;
                            this.miRouter.navigate(["/facturas"]);
    
                          }else{
                            console.log("No se ha podido obtener el logo.");
                            this.showSpinner = false;
                          }                    
                        })

                      }else{
                        console.log("No se ha podido obtener datos establecimiento.");
                        this.showSpinner = false;
                      } 

                    })

                                                            

                  } else {
                    if (Resultado3.booOk != undefined) {
                      this.miRespuesta = Resultado3;
                      console.log(this.miRespuesta.strValor);
                      this.showError=true;
                      this.showSpinner = false;
                      this.message=this.miRespuesta.strValor;
        
                    } else {
                      console.log("El dato de respuesta no se corresponde con ningun objeto previsto.");
                      this.showSpinner = false;
                    }
                  }

                }, (err: HttpErrorResponse) => {
                  if(err.status === 400) {
                    console.log("ERROR 400.");
                    this.showSpinner = false;
                  }
                })

              } else {
                console.log("ERROR: No se obtuvo el token.");
                this.showSpinner = false;
              }

            }, (err: HttpErrorResponse) => {
              if(err.status === 400){
                console.log("ERROR 400.");
                this.showSpinner = false;
              }
            })

        } else {
            if (Resultado1.booOk != undefined) {
              this.miRespuesta = Resultado1;
              console.log(this.miRespuesta.strValor);
              this.showError=true;
              this.showSpinner = false;
              this.message=this.miRespuesta.strValor;

            } else {
              console.log("El dato de respuesta no se corresponde con ningun objeto previsto.");
              this.showSpinner = false;
            }
        }
      }, (err: HttpErrorResponse) => {
        if(err.status === 400) {
          console.log("ERROR 400.");
          this.showSpinner = false;
        }
      })
      
    } catch(e) {
        if(e instanceof Error) {
            // IDE type hinting now available
            console.log(e.message);
            this.showSpinner = false;
        }
        else {
            // probably cannot recover...therefore, rethrow
            throw e;
        }
    }    
  }
}
