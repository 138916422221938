import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { OrdenCobroComponent } from './components/orden-cobro/orden-cobro.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RestService } from './services/rest.service';
import { AppMaterialModule } from './app-material.module';
// import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { StorageService } from './services/storage.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuComponent } from './components/menu/menu.component';
import { MatNativeDateModule, MatPaginatorIntl } from '@angular/material';
import { CustomPaginator } from './resources/Funciones';
import { DetalleOrdenCobroComponent } from './components/detalle-orden-cobro/detalle-orden-cobro.component';
import { PagosComponent } from './components/pagos/pagos.component';
import { ClientesComponent } from './components/clientes/clientes.component';
import { DetalleClienteComponent } from './components/detalle-cliente/detalle-cliente.component';
import { LoginService } from './services/login.service';
import { PasarelaComponent } from './components/pasarela/pasarela.component';
import { DatePipe } from '@angular/common';
import { VentanaEmergenteComponent } from './components/ventana-emergente/ventana-emergente.component';
import { ConfiguracionComponent } from './components/configuracion/configuracion.component';
import { PagoFinalizadoComponent } from './components/pago-finalizado/pago-finalizado.component';
import { VentanaEmergentePagosComponent } from './components/ventana-emergente-pagos/ventana-emergente-pagos.component';
import { FacturasPendientesComponent } from './components/facturas-pendientes/facturas-pendientes.component';
import { CobrosPendientesComponent } from './components/cobros-pendientes/cobros-pendientes.component';
import { MisDatosComponent } from './components/mis-datos/mis-datos.component';
import { StorageServicePagos } from './services/storagePagos.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    OrdenCobroComponent,
    MenuComponent,
    DetalleOrdenCobroComponent,
    PagosComponent,
    ClientesComponent,
    DetalleClienteComponent,
    PasarelaComponent,
    VentanaEmergenteComponent,
    ConfiguracionComponent,
    PagoFinalizadoComponent,
    VentanaEmergentePagosComponent,
    FacturasPendientesComponent,
    CobrosPendientesComponent,
    MisDatosComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    // HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,


    MatNativeDateModule
  ],
  providers: [RestService, StorageService, StorageServicePagos, LoginService, {provide: MatPaginatorIntl, useValue: CustomPaginator() },DatePipe
              
             ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
