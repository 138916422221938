import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { FacturaVentaResult } from '../models/FacturaVentaResult';
import { ClienteResult } from '../models/cliente-result';
import { FacturaVentaObservacionResult } from '../models/FacturaVentaObservacionResult';
import { Respuesta } from '../models/Respuesta';
import { Establecimiento } from '../models/establecimiento';
import { EstablecimientoResult } from '../models/EstablecimientoResult';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  constructor(private http: HttpClient, private storageService: StorageService ) { }
  
  public url: string = environment.baseUrl;  
  headers = new HttpHeaders().set('Content-Type','application/json');

  private strToken=this.storageService.getCurrentToken();

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer ' + this.strToken  })
  };
    
  apiOrdenCobro(strCookie: string, intId: string, intIdCliente: string, strEstado: string, strBusqueda: string): Observable<any>{
    let dato = '{"strCookie": "'+ strCookie +'", "intId":"'+ intId +'", "intIdCliente":"'+ intIdCliente +'", "strEstado":"'+ strEstado +'", "strBusqueda":"'+ strBusqueda +'"}';
    return this.http.post<any>(this.url + '/api/ordencobro', dato, {headers: this.headers});
  }

  getFacturaVenta(intId,intIdCliente,strEstado,strBusqueda):Observable<FacturaVentaResult>{
    let dato='strCookie='+this.storageService.getCurrentSession().strCookie+'&intId='+intId+'&intIdCliente='+intIdCliente+'&strEstado='+strEstado+'&strBusqueda='+strBusqueda;
    let urlCompleta=this.url+'/api/FacturaVenta?'+dato;
 
    return this.http.get<FacturaVentaResult>(urlCompleta,this.httpOptions);
  }

  postFacturaVenta(NuevaOrdenCobro,fecha):Observable<FacturaVentaResult>{
    let dato='strCookie='+this.storageService.getCurrentSession().strCookie;
    let urlCompleta=this.url+'/api/FacturaVenta?'+dato;

    NuevaOrdenCobro.datFechaFinTransaccion=fecha;

    var jsonString = JSON.stringify(NuevaOrdenCobro);
 
    return this.http.post<FacturaVentaResult>(urlCompleta,jsonString,this.httpOptions);
  }

  putFacturaVenta(NuevaOrdenCobro,fecha):Observable<FacturaVentaResult>{
    let dato='strCookie='+this.storageService.getCurrentSession().strCookie;
    let urlCompleta=this.url+'/api/FacturaVenta?'+dato;

    NuevaOrdenCobro.datFechaFinTransaccion=fecha;

    var jsonString = JSON.stringify(NuevaOrdenCobro);
 
    return this.http.put<FacturaVentaResult>(urlCompleta,jsonString,this.httpOptions);
  }

  getCliente(intId):Observable<ClienteResult>{
    let dato='strCookie='+this.storageService.getCurrentSession().strCookie+'&intId='+intId;
    let urlCompleta=this.url+'/api/cliente/detalle?'+dato;
 
    return this.http.get<ClienteResult>(urlCompleta,this.httpOptions);
  }

  getClientePago(Cookie,intId):Observable<ClienteResult>{
    let dato='strCookie='+Cookie+'&intId='+intId;
    let urlCompleta=this.url+'/api/cliente/extranet/pago?'+dato;
 
    return this.http.get<ClienteResult>(urlCompleta,{headers:this.headers});
  }

  postCliente(NuevoCliente):Observable<ClienteResult>{
    let dato='strCookie='+this.storageService.getCurrentSession().strCookie;
    let urlCompleta=this.url+'/api/cliente/agregar?'+dato;

    var jsonString = JSON.stringify(NuevoCliente);
 
    return this.http.post<ClienteResult>(urlCompleta,jsonString,this.httpOptions);
  }

  putCliente(NuevoCliente):Observable<ClienteResult>{
    let dato='strCookie='+this.storageService.getCurrentSession().strCookie;
    let urlCompleta=this.url+'/api/cliente/admin/actualiza?'+dato;

    var jsonString = JSON.stringify(NuevoCliente);
 
    return this.http.put<ClienteResult>(urlCompleta,jsonString,this.httpOptions);
  }

  //Cambiar contraseña
  // cambioContrasena(cookie,miObjeto,intCliente):Observable<ClienteResult>{
  //   let dato='strCookie='+cookie+'&intCliente='+intCliente;
  //   let urlCompleta=this.url+'/api/cliente/contrasena?'+dato;

  //   var jsonString = JSON.stringify(miObjeto);

  //   return this.http.put<ClienteResult>(urlCompleta,jsonString,this.httpOptions);
  // }

  envioEmail(strUrl, intCliente, intCobro):Observable<any>{
    let dato='strCookie='+this.storageService.getCurrentSession().strCookie;
    let urlCompleta=this.url+'/api/FacturaVenta/email?'+dato+'&strUrl='+strUrl+'&intId='+intCliente+'&intCobro='+intCobro;
 
    return this.http.get<any>(urlCompleta,this.httpOptions);
  }

  postPasarela(cookieInfi,cookieGestion){
    let dato='strCookie='+cookieInfi;
    let urlCompleta=this.url+'/api/Pasarela?';

    var body = '{"strImporte":"" ,"strOrdenCobroId":"", "strCookieInfi":"'+ cookieInfi +'", "strCookieGestion":"'+ cookieGestion +'", "strNumeroOrden":""}';
 
    return this.http.post(urlCompleta,body,{headers:this.headers});
  }

  //Recupero el pago
  recuperaDato(cookieInfi,cookieGestion){
    let dato='strCookie='+cookieInfi;
    let urlCompleta=this.url+'/api/Pasarela?'+dato;

    var body = '{"strImporte":"" ,"strOrdenCobroId":"", "strCookieInfi":"'+ cookieInfi +'", "strCookieGestion":"'+ cookieGestion +'", "strNumeroOrden":""}';
 
    return this.http.put(urlCompleta,body,{headers:this.headers});
  }

  getDetalle(cookieInfi,strCookieT):Observable<FacturaVentaResult>{
    let urlCompleta=this.url+'/api/FacturaVenta/detalle?strCookie='+cookieInfi+'&strCookieT='+strCookieT;
 
    return this.http.get<FacturaVentaResult>(urlCompleta,{headers:this.headers});
  }

  getFacturaVentaObservaciones(strCookie,intId):Observable<FacturaVentaObservacionResult>{
    let dato='strCookie='+strCookie+'&intId='+intId;
    let urlCompleta=this.url+'/api/FacturaVentaObservacion?'+dato;
 
    return this.http.get<FacturaVentaObservacionResult>(urlCompleta,{headers:this.headers});
  }

  // getEst(cookieInfi):Observable<any>{
  //   let urlCompleta=this.url+'/api/Establecimiento?strCookie='+cookieInfi+'&datoAdicional=2';
 
  //   return this.http.get<any>(urlCompleta,{headers:this.headers});
  // }

  getDatosGeneralesEst(cookie):Observable<any>{
    let urlCompleta=this.url+'/api/establecimiento/general?strCookie='+cookie;
 
    return this.http.get<any>(urlCompleta,{headers:this.headers});
  }

  getPass(intId):Observable<ClienteResult>{
    let urlCompleta=this.url+'/api/Cliente/pass?strCookie='+this.storageService.getCurrentSession().strCookie;

    var jsonString = JSON.stringify(intId);
 
    return this.http.post<ClienteResult>(urlCompleta,jsonString,this.httpOptions);
  }

  getEstablecimiento():Observable<EstablecimientoResult>{
    let dato='strCookie='+this.storageService.getCurrentSession().strCookie;
    let urlCompleta=this.url+'/api/establecimiento/config?'+dato;
 
    return this.http.get<EstablecimientoResult>(urlCompleta,this.httpOptions);
  }

  putEstablecimiento(establacimiento):Observable<EstablecimientoResult>{
    let dato='strCookie='+this.storageService.getCurrentSession().strCookie;
    let urlCompleta=this.url+'/api/Establecimiento/admin/cambio?'+dato;

    var jsonString = JSON.stringify(establacimiento);
 
    return this.http.put<EstablecimientoResult>(urlCompleta,jsonString,this.httpOptions);
  }

  /********** PAGO POR TRANSFERENCIA *************/
  pagoTransf(cookieInfi, intCliente, intCobro,strEmail,strFormaPago):Observable<any>{
    let urlCompleta=this.url+'/api/Pago/email?strCookie='+cookieInfi+'&intId='+intCliente+'&intCobro='+intCobro+'&strEmail='+strEmail+'&strFormaPago='+strFormaPago;
 
    return this.http.get<any>(urlCompleta,{headers:this.headers});
  }

  /********** PAGO POR CARGO EN CUENTA *************/
  addCC(cookieInfi,ordenCobro):Observable<any>{
    let urlCompleta=this.url+'/api/Pago/cc?strCookie='+cookieInfi;
    var jsonString = JSON.stringify(ordenCobro);
 
    return this.http.put<any>(urlCompleta,jsonString,{headers:this.headers});
  }

  /********** Exportar a EXCEL *************/

  exportAsExcelFile(json: any[], excelFileName: string): void {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

}
