import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { OrdenCobroComponent } from './components/orden-cobro/orden-cobro.component';
import { DetalleOrdenCobroComponent } from './components/detalle-orden-cobro/detalle-orden-cobro.component';
import { PagosComponent } from './components/pagos/pagos.component';
import { ClientesComponent } from './components/clientes/clientes.component';
import { DetalleClienteComponent } from './components/detalle-cliente/detalle-cliente.component';
import { PasarelaComponent } from './components/pasarela/pasarela.component';
import { VentanaEmergenteComponent } from './components/ventana-emergente/ventana-emergente.component';
import { AuthAdminGuard } from './guards/authAdminGuard';
import { ConfiguracionComponent } from './components/configuracion/configuracion.component';
import { PagoFinalizadoComponent } from './components/pago-finalizado/pago-finalizado.component';
import { VentanaEmergentePagosComponent } from './components/ventana-emergente-pagos/ventana-emergente-pagos.component';
import { FacturasPendientesComponent } from './components/facturas-pendientes/facturas-pendientes.component';
import { AuthGuard } from './guards/auth.guard';
import { CobrosPendientesComponent } from './components/cobros-pendientes/cobros-pendientes.component';
import { MisDatosComponent } from './components/mis-datos/mis-datos.component';


const routes: Routes = [
  /** LISTA DE GENERAL DE COMPONENTES **/
  { path: 'login', component: LoginComponent },
  { path: 'facturas', component: OrdenCobroComponent, canActivate: [ AuthGuard ]},
  { path: 'cobros-pendientes', component: CobrosPendientesComponent, canActivate: [ AuthAdminGuard ]},
  { path: 'facturas-pendientes', component: FacturasPendientesComponent, canActivate: [ AuthGuard ]},
  { path: 'detalle-orden-cobro', component: DetalleOrdenCobroComponent },
  { path: 'pagos', component: PagosComponent },
  { path: 'clientes', component: ClientesComponent , canActivate: [ AuthAdminGuard ]},
  { path: 'detalle-cliente', component: DetalleClienteComponent },
  { path: 'area-cliente', component: MisDatosComponent , canActivate: [ AuthGuard ]},
  { path: 'pasarela', component: PasarelaComponent },
  { path: 'ventana-emergente', component: VentanaEmergenteComponent },
  { path: 'ventana-emergente-pagos', component: VentanaEmergentePagosComponent },
  { path: 'configuracion', component: ConfiguracionComponent , canActivate: [ AuthAdminGuard ]},
  { path: 'PagoFinalizado', component: PagoFinalizadoComponent },

  /** PERMISOS PARA COMPONENTES - GUARDS ***/

  /** COMPONENTES POR DEFECTO **/
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
